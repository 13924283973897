<template>
  <div class="box p-2">
    <TabMenu v-model:activeIndex="active" :model="items">
      <template #item="{ label, item, props }">
        <router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
          <a
            :href="routerProps.href"
            v-bind="props.action"
            @click="($event) => routerProps.navigate($event)"
            @keydown.enter.space="($event) => routerProps.navigate($event)">
            <span v-bind="props.icon"/>
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
      </template>
    </TabMenu>
    <section class="box p-4">
      <router-view/>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const active = ref(0)
const items = ref([
  {
    label: 'Entradas de mercancia',
    icon: 'pi pi-fw pi-home',
    route: { name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas' },
    activeName: [
      'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas',
      'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas.crear'
    ]
  },
  {
    label: 'Salidas de mercancia',
    icon: 'pi pi-fw pi-calendar',
    route: { name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas' },
    activeName: [
      'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas',
      'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas.crear'
    ]
  }
])

onMounted(() => {
  active.value = items.value.findIndex(item => item.activeName.some(x => x === route.name))
})

watch(
  route,
  () => {
    const _value = items.value.findIndex(item => item.activeName.some(x => x === route.name))
    active.value = _value
  },
  { immediate: true }
)
</script>

<style scoped>

</style>
